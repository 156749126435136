
.module-stage {
  padding: 32px 0 0 0;

  @include breakpoint(s) {
    height: 100vh;
  }

  @include breakpoint(m) {
    padding: 40px 0;
  }

  @include breakpoint(l) {
    padding-top: 184px;
    padding-bottom: 64px;
  }

  &__inner {

    @include breakpoint(m, l) {
      position: relative;
    }
  }

  &__headline {
    @extend %t-headline;
  }

  &__subline {
    @extend %t-subline;
  }

  &__hero {
    margin: 0 (- map-pull($breakpoints, s, container, padding));

    @include breakpoint(m, l) {
      margin: 0;
    }
  }

  &__image {
    @include breakpoint(s) {
      position: absolute;
      top: 248px;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @include breakpoint(m, l) {
      position: relative;
      margin-top: -184px;
      height: 504px;
    }

    @include breakpoint(l) {
      margin-top: -184px;
      padding-bottom: 0;
      height: 100vh;
    }
  }

  &__receiver {
    padding: 32px 0;

    @include breakpoint(m) {
      padding-top: 12px;
    }

    @include breakpoint(l) {
      padding-top: 40px;
    }
  }

  &__dash-vertical {
    display: block;
    position: absolute;
    top: 216px;
    left: 32px;
    width: 4px;
    height: 208px;
    background: #D50C19;
    z-index: 1;

    @include breakpoint(m, l) {
      display: none;
    }
  }

  &__dash-horizontal {
    display: block;
    position: relative;
    width: 600px;
    height: 4px;
    margin-top: 32px;
    background: #D50C19;
    z-index: 1;

    @include breakpoint(m) {
      width: 280px;
      height: 3px;
      margin-top: 10px;
    }

    @include breakpoint(s) {
      display: none;
    }
  }

  &__receiver-label {
    @extend %t-subline;
  }

  &__receiver-logo {
    position: relative;
    height: 40px;
    margin-top: 4px;
    margin-left: -8px;

    @include breakpoint(m) {
      height: 32px;
      margin-top: 8px;
    }

    @include breakpoint(l) {
      height: 80px;
      margin-left: -16px;
    }
  }

  &__action-open {
    position: absolute;
    bottom: 32px;
    left: 32px;
    right: 32px;
    z-index: 1;

    @include breakpoint(m) {
      left: 0;
      right: auto;
      width: 152px;
    }

    @include breakpoint(l) {
      left: 0;
      right: auto;
      bottom: 64px;
    }
  }

  &__action-message {
    position: absolute;
    bottom: 64px;
    right: 64px;
    z-index: 1;

    @include breakpoint(s, m) {
      display: none;
    }
  }
}
