
.teaser-article {
  display: block;

  &__image {
    display: block;
    overflow: hidden;
    line-height: 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(102, 90, 90, 50);
  }

  &__headline {
    padding-top: 24px;
    @extend %t-h3;

    @include breakpoint(l) {
      padding-top: 32px;
    }
  }

  &__subline {
    @extend %t-text;
  }
}
