
.list-primary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include breakpoint(l) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -24px;
  }

  &__item {
    padding-top: 20px;
    border-bottom: 2px solid $c-secondary;
    @extend %t-skill;

    @include breakpoint(l) {
      margin: 0 24px;
      border-bottom-width: 4px;
    }
  }
}
