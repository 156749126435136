
.button {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  @extend %t-button;
  background: $c-primary;
  color: $c-white;

  @include breakpoint(m) {
    padding: 10px 16px;
    max-width: 320px;
  }

  @include breakpoint(l) {
    padding: 22px 16px;
    max-width: 320px;
  }

  &--secondary {
    padding: 2px 16px;
    border: 2px solid $c-primary;
    border-radius: 5px;
    background: transparent;
    color: $c-primary;

    @include breakpoint(l) {
      padding: 10px 16px;
    }
  }

  &--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;

    svg {
      width: 100%;
      height: 100%;
    }

    @include breakpoint(m) {
      width: 32px;
      height: 32px;
    }
  }

  &--space-top {
    margin-top: 24px;

    @include breakpoint(l) {
      margin-top: 64px;
    }
  }
}
