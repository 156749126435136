
.module-section {
  padding: 24px 0;

  @include breakpoint(m, l) {
    padding: 64px 0;
  }

  &__headline {
    @extend %t-headline;
  }

  &__subline {
    @extend %t-subline;
  }

  &__header {
    padding-bottom: 24px;
  }

  &__teaser {
    padding-bottom: 64px;
  }

  &__action {
    padding-top: 32px;

    @include breakpoint(l) {
      padding-top: 48px;
    }
  }

  &__content {

    h3 {
      @extend %t-h3;
    }

    p {
      @extend %t-text;
    }

    h3 + p,
    p + p {
      padding-top: 24px;

      @include breakpoint(l) {
        padding-top: 32px;
      }
    }

    ul {

      li {
        padding-left: 32px;
        @extend %t-text;

        &::before {
          display: inline-block;
          content: "\2B29";
          width: 32px;
          margin-left: -32px;
          color: $c-secondary;
        }
      }

      li + li {
        padding-top: 16px;

        @include breakpoint(l) {
          padding-top: 24px;
        }
      }
    }
  }

  &__content + &__articles {
    padding-top: 48px;
  }

  &__articles {
    margin-top: -24px;

    .teaser-article {
      padding-top: 24px;
    }
  }
}
