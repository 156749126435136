
* {
  outline: none;
  box-sizing: border-box;
  transition-timing-function: ease;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: $default-font-size;
}

body {
  background: $c-background;
  font-family: $default-font-family;
  line-height: $default-line-height;
  word-wrap: break-word;
}

body,
button,
input,
select,
textarea {
  color: $c-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  resize: none;
}

table {
  border-collapse: collapse;

  th,
  td {
    padding: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
}

a {
  text-decoration: inherit;
  color: inherit;
}

img {
  width: 100%;
}
