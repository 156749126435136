
body {
  padding-bottom: 96px;

  @include breakpoint(m, l) {
    padding-bottom: 160px;
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 32px;
  transform: translate(0, 100%);
  visibility: hidden;
  transition: transform 0.3s, visibility 0.3s;
  z-index: $z-index-footer;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 32px;
    background: $c-background-shaded;
    border-top: 1px solid $c-line;
  }

  &__action {
    position: relative;
    z-index: 1;
  }

  &--visible {
    transform: none;
    visibility: visible;
  }
}
