
%t-text {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.01px;
  line-height: 24px;

  @include breakpoint(l) {
    font-size: 28px;
    line-height: 34px;
  }
}

%t-headline {
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.41px;
  line-height: 30px;

  @include breakpoint(m) {
    font-size: 18px;
    line-height: 22px;
  }

  @include breakpoint(l) {
    font-size: 38px;
    line-height: 47px;
  }
}

%t-subline {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.41px;
  line-height: 30px;

  @include breakpoint(m) {
    font-size: 14px;
    line-height: 22px;
  }

  @include breakpoint(l) {
    font-size: 28px;
    line-height: 47px;
  }
}

%t-h3 {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.01px;
  line-height: 24px;

  @include breakpoint(l) {
    font-size: 28px;
    line-height: 34px;
  }
}

%t-button {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.01px;
  line-height: 32px;

  @include breakpoint(m) {
    font-size: 12px;
    line-height: 16px;
  }

  @include breakpoint(l) {
    font-size: 28px;
    line-height: 34px;
  }
}

%t-skill {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.41px;
  line-height: 22px;

  @include breakpoint(l) {
    font-size: 28px;
    line-height: 34px;
  }
}
