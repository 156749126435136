
.image {
  $block: &;
  display: block;

  &--cover,
  &--contain {
    // Cover parent element
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__img {
    width: 100%;

    #{$block}--cover &,
    #{$block}--contain & {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    #{$block}--contain & {
      object-fit: contain;
    }

    #{$block}--position-left-center & {
      object-position: left center;
    }
  }
}
