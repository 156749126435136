.media__subline, .teaser-article__subline, .module-section__content p, .module-section__content ul li {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.01px;
  line-height: 24px;
}

@media (min-width: 1000px) {
  .media__subline, .teaser-article__subline, .module-section__content p, .module-section__content ul li {
    font-size: 28px;
    line-height: 34px;
  }
}

.module-stage__headline, .module-section__headline {
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.41px;
  line-height: 30px;
}

@media (min-width: 600px) and (max-width: 999px) {
  .module-stage__headline, .module-section__headline {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (min-width: 1000px) {
  .module-stage__headline, .module-section__headline {
    font-size: 38px;
    line-height: 47px;
  }
}

.module-stage__subline, .module-stage__receiver-label, .module-section__subline {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.41px;
  line-height: 30px;
}

@media (min-width: 600px) and (max-width: 999px) {
  .module-stage__subline, .module-stage__receiver-label, .module-section__subline {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (min-width: 1000px) {
  .module-stage__subline, .module-stage__receiver-label, .module-section__subline {
    font-size: 28px;
    line-height: 47px;
  }
}

.media__headline, .teaser-article__headline, .module-section__content h3 {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.01px;
  line-height: 24px;
}

@media (min-width: 1000px) {
  .media__headline, .teaser-article__headline, .module-section__content h3 {
    font-size: 28px;
    line-height: 34px;
  }
}

.button {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.01px;
  line-height: 32px;
}

@media (min-width: 600px) and (max-width: 999px) {
  .button {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (min-width: 1000px) {
  .button {
    font-size: 28px;
    line-height: 34px;
  }
}

.list-primary__item {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.41px;
  line-height: 22px;
}

@media (min-width: 1000px) {
  .list-primary__item {
    font-size: 28px;
    line-height: 34px;
  }
}

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

* {
  outline: none;
  box-sizing: border-box;
  transition-timing-function: ease;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 1rem;
}

body {
  background: #ffffff;
  font-family: Montserrat, sans-serif;
  line-height: 1.15;
  word-wrap: break-word;
}

body,
button,
input,
select,
textarea {
  color: #343434;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  resize: none;
}

table {
  border-collapse: collapse;
}

table th,
table td {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
}

a {
  text-decoration: inherit;
  color: inherit;
}

img {
  width: 100%;
}

@media (min-width: 0) and (max-width: 599px) {
  .gr-container,
  .gr-container\@s {
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
  }
  .gr-row,
  .gr-row\@s {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
    box-sizing: border-box;
    margin-left: -6px;
    margin-right: -6px;
  }
  .gr-1, .gr-2, .gr-3, .gr-4, .gr-5, .gr-6, .gr-7, .gr-8, .gr-9, .gr-10, .gr-11, .gr-12, .gr-1\@s, .gr-2\@s, .gr-3\@s, .gr-4\@s, .gr-5\@s, .gr-6\@s, .gr-7\@s, .gr-8\@s, .gr-9\@s, .gr-10\@s, .gr-11\@s, .gr-12\@s {
    padding-left: 6px;
    padding-right: 6px;
    box-sizing: border-box;
  }
  .gr-1 {
    width: 8.33333%;
  }
  .gr-2 {
    width: 16.66667%;
  }
  .gr-3 {
    width: 25%;
  }
  .gr-4 {
    width: 33.33333%;
  }
  .gr-5 {
    width: 41.66667%;
  }
  .gr-6 {
    width: 50%;
  }
  .gr-7 {
    width: 58.33333%;
  }
  .gr-8 {
    width: 66.66667%;
  }
  .gr-9 {
    width: 75%;
  }
  .gr-10 {
    width: 83.33333%;
  }
  .gr-11 {
    width: 91.66667%;
  }
  .gr-12 {
    width: 100%;
  }
  .gr-1\@s {
    width: 8.33333%;
  }
  .gr-2\@s {
    width: 16.66667%;
  }
  .gr-3\@s {
    width: 25%;
  }
  .gr-4\@s {
    width: 33.33333%;
  }
  .gr-5\@s {
    width: 41.66667%;
  }
  .gr-6\@s {
    width: 50%;
  }
  .gr-7\@s {
    width: 58.33333%;
  }
  .gr-8\@s {
    width: 66.66667%;
  }
  .gr-9\@s {
    width: 75%;
  }
  .gr-10\@s {
    width: 83.33333%;
  }
  .gr-11\@s {
    width: 91.66667%;
  }
  .gr-12\@s {
    width: 100%;
  }
  .gr--prefix-0 {
    margin-left: 0%;
  }
  .gr--suffix-0 {
    margin-right: 0%;
  }
  .gr--prefix-1 {
    margin-left: 8.33333%;
  }
  .gr--suffix-1 {
    margin-right: 8.33333%;
  }
  .gr--prefix-2 {
    margin-left: 16.66667%;
  }
  .gr--suffix-2 {
    margin-right: 16.66667%;
  }
  .gr--prefix-3 {
    margin-left: 25%;
  }
  .gr--suffix-3 {
    margin-right: 25%;
  }
  .gr--prefix-4 {
    margin-left: 33.33333%;
  }
  .gr--suffix-4 {
    margin-right: 33.33333%;
  }
  .gr--prefix-5 {
    margin-left: 41.66667%;
  }
  .gr--suffix-5 {
    margin-right: 41.66667%;
  }
  .gr--prefix-6 {
    margin-left: 50%;
  }
  .gr--suffix-6 {
    margin-right: 50%;
  }
  .gr--prefix-7 {
    margin-left: 58.33333%;
  }
  .gr--suffix-7 {
    margin-right: 58.33333%;
  }
  .gr--prefix-8 {
    margin-left: 66.66667%;
  }
  .gr--suffix-8 {
    margin-right: 66.66667%;
  }
  .gr--prefix-9 {
    margin-left: 75%;
  }
  .gr--suffix-9 {
    margin-right: 75%;
  }
  .gr--prefix-10 {
    margin-left: 83.33333%;
  }
  .gr--suffix-10 {
    margin-right: 83.33333%;
  }
  .gr--prefix-11 {
    margin-left: 91.66667%;
  }
  .gr--suffix-11 {
    margin-right: 91.66667%;
  }
  .gr--prefix-12 {
    margin-left: 100%;
  }
  .gr--suffix-12 {
    margin-right: 100%;
  }
  .gr--prefix-0\@s {
    margin-left: 0%;
  }
  .gr--suffix-0\@s {
    margin-right: 0%;
  }
  .gr--prefix-1\@s {
    margin-left: 8.33333%;
  }
  .gr--suffix-1\@s {
    margin-right: 8.33333%;
  }
  .gr--prefix-2\@s {
    margin-left: 16.66667%;
  }
  .gr--suffix-2\@s {
    margin-right: 16.66667%;
  }
  .gr--prefix-3\@s {
    margin-left: 25%;
  }
  .gr--suffix-3\@s {
    margin-right: 25%;
  }
  .gr--prefix-4\@s {
    margin-left: 33.33333%;
  }
  .gr--suffix-4\@s {
    margin-right: 33.33333%;
  }
  .gr--prefix-5\@s {
    margin-left: 41.66667%;
  }
  .gr--suffix-5\@s {
    margin-right: 41.66667%;
  }
  .gr--prefix-6\@s {
    margin-left: 50%;
  }
  .gr--suffix-6\@s {
    margin-right: 50%;
  }
  .gr--prefix-7\@s {
    margin-left: 58.33333%;
  }
  .gr--suffix-7\@s {
    margin-right: 58.33333%;
  }
  .gr--prefix-8\@s {
    margin-left: 66.66667%;
  }
  .gr--suffix-8\@s {
    margin-right: 66.66667%;
  }
  .gr--prefix-9\@s {
    margin-left: 75%;
  }
  .gr--suffix-9\@s {
    margin-right: 75%;
  }
  .gr--prefix-10\@s {
    margin-left: 83.33333%;
  }
  .gr--suffix-10\@s {
    margin-right: 83.33333%;
  }
  .gr--prefix-11\@s {
    margin-left: 91.66667%;
  }
  .gr--suffix-11\@s {
    margin-right: 91.66667%;
  }
  .gr--prefix-12\@s {
    margin-left: 100%;
  }
  .gr--suffix-12\@s {
    margin-right: 100%;
  }
}

@media (min-width: 600px) and (max-width: 999px) {
  .gr-container,
  .gr-container\@m {
    max-width: 100%;
    padding-left: 48px;
    padding-right: 48px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
  }
  .gr-row,
  .gr-row\@m {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
    box-sizing: border-box;
    margin-left: -8px;
    margin-right: -8px;
  }
  .gr-1, .gr-2, .gr-3, .gr-4, .gr-5, .gr-6, .gr-7, .gr-8, .gr-9, .gr-10, .gr-11, .gr-12, .gr-1\@m, .gr-2\@m, .gr-3\@m, .gr-4\@m, .gr-5\@m, .gr-6\@m, .gr-7\@m, .gr-8\@m, .gr-9\@m, .gr-10\@m, .gr-11\@m, .gr-12\@m {
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
  }
  .gr-1 {
    width: 8.33333%;
  }
  .gr-2 {
    width: 16.66667%;
  }
  .gr-3 {
    width: 25%;
  }
  .gr-4 {
    width: 33.33333%;
  }
  .gr-5 {
    width: 41.66667%;
  }
  .gr-6 {
    width: 50%;
  }
  .gr-7 {
    width: 58.33333%;
  }
  .gr-8 {
    width: 66.66667%;
  }
  .gr-9 {
    width: 75%;
  }
  .gr-10 {
    width: 83.33333%;
  }
  .gr-11 {
    width: 91.66667%;
  }
  .gr-12 {
    width: 100%;
  }
  .gr-1\@m {
    width: 8.33333%;
  }
  .gr-2\@m {
    width: 16.66667%;
  }
  .gr-3\@m {
    width: 25%;
  }
  .gr-4\@m {
    width: 33.33333%;
  }
  .gr-5\@m {
    width: 41.66667%;
  }
  .gr-6\@m {
    width: 50%;
  }
  .gr-7\@m {
    width: 58.33333%;
  }
  .gr-8\@m {
    width: 66.66667%;
  }
  .gr-9\@m {
    width: 75%;
  }
  .gr-10\@m {
    width: 83.33333%;
  }
  .gr-11\@m {
    width: 91.66667%;
  }
  .gr-12\@m {
    width: 100%;
  }
  .gr--prefix-0 {
    margin-left: 0%;
  }
  .gr--suffix-0 {
    margin-right: 0%;
  }
  .gr--prefix-1 {
    margin-left: 8.33333%;
  }
  .gr--suffix-1 {
    margin-right: 8.33333%;
  }
  .gr--prefix-2 {
    margin-left: 16.66667%;
  }
  .gr--suffix-2 {
    margin-right: 16.66667%;
  }
  .gr--prefix-3 {
    margin-left: 25%;
  }
  .gr--suffix-3 {
    margin-right: 25%;
  }
  .gr--prefix-4 {
    margin-left: 33.33333%;
  }
  .gr--suffix-4 {
    margin-right: 33.33333%;
  }
  .gr--prefix-5 {
    margin-left: 41.66667%;
  }
  .gr--suffix-5 {
    margin-right: 41.66667%;
  }
  .gr--prefix-6 {
    margin-left: 50%;
  }
  .gr--suffix-6 {
    margin-right: 50%;
  }
  .gr--prefix-7 {
    margin-left: 58.33333%;
  }
  .gr--suffix-7 {
    margin-right: 58.33333%;
  }
  .gr--prefix-8 {
    margin-left: 66.66667%;
  }
  .gr--suffix-8 {
    margin-right: 66.66667%;
  }
  .gr--prefix-9 {
    margin-left: 75%;
  }
  .gr--suffix-9 {
    margin-right: 75%;
  }
  .gr--prefix-10 {
    margin-left: 83.33333%;
  }
  .gr--suffix-10 {
    margin-right: 83.33333%;
  }
  .gr--prefix-11 {
    margin-left: 91.66667%;
  }
  .gr--suffix-11 {
    margin-right: 91.66667%;
  }
  .gr--prefix-12 {
    margin-left: 100%;
  }
  .gr--suffix-12 {
    margin-right: 100%;
  }
  .gr--prefix-0\@m {
    margin-left: 0%;
  }
  .gr--suffix-0\@m {
    margin-right: 0%;
  }
  .gr--prefix-1\@m {
    margin-left: 8.33333%;
  }
  .gr--suffix-1\@m {
    margin-right: 8.33333%;
  }
  .gr--prefix-2\@m {
    margin-left: 16.66667%;
  }
  .gr--suffix-2\@m {
    margin-right: 16.66667%;
  }
  .gr--prefix-3\@m {
    margin-left: 25%;
  }
  .gr--suffix-3\@m {
    margin-right: 25%;
  }
  .gr--prefix-4\@m {
    margin-left: 33.33333%;
  }
  .gr--suffix-4\@m {
    margin-right: 33.33333%;
  }
  .gr--prefix-5\@m {
    margin-left: 41.66667%;
  }
  .gr--suffix-5\@m {
    margin-right: 41.66667%;
  }
  .gr--prefix-6\@m {
    margin-left: 50%;
  }
  .gr--suffix-6\@m {
    margin-right: 50%;
  }
  .gr--prefix-7\@m {
    margin-left: 58.33333%;
  }
  .gr--suffix-7\@m {
    margin-right: 58.33333%;
  }
  .gr--prefix-8\@m {
    margin-left: 66.66667%;
  }
  .gr--suffix-8\@m {
    margin-right: 66.66667%;
  }
  .gr--prefix-9\@m {
    margin-left: 75%;
  }
  .gr--suffix-9\@m {
    margin-right: 75%;
  }
  .gr--prefix-10\@m {
    margin-left: 83.33333%;
  }
  .gr--suffix-10\@m {
    margin-right: 83.33333%;
  }
  .gr--prefix-11\@m {
    margin-left: 91.66667%;
  }
  .gr--suffix-11\@m {
    margin-right: 91.66667%;
  }
  .gr--prefix-12\@m {
    margin-left: 100%;
  }
  .gr--suffix-12\@m {
    margin-right: 100%;
  }
}

@media (min-width: 1000px) {
  .gr-container,
  .gr-container\@l {
    max-width: 1320px;
    padding-left: 64px;
    padding-right: 64px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
  }
  .gr-row,
  .gr-row\@l {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
    box-sizing: border-box;
    margin-left: -16px;
    margin-right: -16px;
  }
  .gr-1, .gr-2, .gr-3, .gr-4, .gr-5, .gr-6, .gr-7, .gr-8, .gr-9, .gr-10, .gr-11, .gr-12, .gr-1\@l, .gr-2\@l, .gr-3\@l, .gr-4\@l, .gr-5\@l, .gr-6\@l, .gr-7\@l, .gr-8\@l, .gr-9\@l, .gr-10\@l, .gr-11\@l, .gr-12\@l {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
  .gr-1 {
    width: 8.33333%;
  }
  .gr-2 {
    width: 16.66667%;
  }
  .gr-3 {
    width: 25%;
  }
  .gr-4 {
    width: 33.33333%;
  }
  .gr-5 {
    width: 41.66667%;
  }
  .gr-6 {
    width: 50%;
  }
  .gr-7 {
    width: 58.33333%;
  }
  .gr-8 {
    width: 66.66667%;
  }
  .gr-9 {
    width: 75%;
  }
  .gr-10 {
    width: 83.33333%;
  }
  .gr-11 {
    width: 91.66667%;
  }
  .gr-12 {
    width: 100%;
  }
  .gr-1\@l {
    width: 8.33333%;
  }
  .gr-2\@l {
    width: 16.66667%;
  }
  .gr-3\@l {
    width: 25%;
  }
  .gr-4\@l {
    width: 33.33333%;
  }
  .gr-5\@l {
    width: 41.66667%;
  }
  .gr-6\@l {
    width: 50%;
  }
  .gr-7\@l {
    width: 58.33333%;
  }
  .gr-8\@l {
    width: 66.66667%;
  }
  .gr-9\@l {
    width: 75%;
  }
  .gr-10\@l {
    width: 83.33333%;
  }
  .gr-11\@l {
    width: 91.66667%;
  }
  .gr-12\@l {
    width: 100%;
  }
  .gr--prefix-0 {
    margin-left: 0%;
  }
  .gr--suffix-0 {
    margin-right: 0%;
  }
  .gr--prefix-1 {
    margin-left: 8.33333%;
  }
  .gr--suffix-1 {
    margin-right: 8.33333%;
  }
  .gr--prefix-2 {
    margin-left: 16.66667%;
  }
  .gr--suffix-2 {
    margin-right: 16.66667%;
  }
  .gr--prefix-3 {
    margin-left: 25%;
  }
  .gr--suffix-3 {
    margin-right: 25%;
  }
  .gr--prefix-4 {
    margin-left: 33.33333%;
  }
  .gr--suffix-4 {
    margin-right: 33.33333%;
  }
  .gr--prefix-5 {
    margin-left: 41.66667%;
  }
  .gr--suffix-5 {
    margin-right: 41.66667%;
  }
  .gr--prefix-6 {
    margin-left: 50%;
  }
  .gr--suffix-6 {
    margin-right: 50%;
  }
  .gr--prefix-7 {
    margin-left: 58.33333%;
  }
  .gr--suffix-7 {
    margin-right: 58.33333%;
  }
  .gr--prefix-8 {
    margin-left: 66.66667%;
  }
  .gr--suffix-8 {
    margin-right: 66.66667%;
  }
  .gr--prefix-9 {
    margin-left: 75%;
  }
  .gr--suffix-9 {
    margin-right: 75%;
  }
  .gr--prefix-10 {
    margin-left: 83.33333%;
  }
  .gr--suffix-10 {
    margin-right: 83.33333%;
  }
  .gr--prefix-11 {
    margin-left: 91.66667%;
  }
  .gr--suffix-11 {
    margin-right: 91.66667%;
  }
  .gr--prefix-12 {
    margin-left: 100%;
  }
  .gr--suffix-12 {
    margin-right: 100%;
  }
  .gr--prefix-0\@l {
    margin-left: 0%;
  }
  .gr--suffix-0\@l {
    margin-right: 0%;
  }
  .gr--prefix-1\@l {
    margin-left: 8.33333%;
  }
  .gr--suffix-1\@l {
    margin-right: 8.33333%;
  }
  .gr--prefix-2\@l {
    margin-left: 16.66667%;
  }
  .gr--suffix-2\@l {
    margin-right: 16.66667%;
  }
  .gr--prefix-3\@l {
    margin-left: 25%;
  }
  .gr--suffix-3\@l {
    margin-right: 25%;
  }
  .gr--prefix-4\@l {
    margin-left: 33.33333%;
  }
  .gr--suffix-4\@l {
    margin-right: 33.33333%;
  }
  .gr--prefix-5\@l {
    margin-left: 41.66667%;
  }
  .gr--suffix-5\@l {
    margin-right: 41.66667%;
  }
  .gr--prefix-6\@l {
    margin-left: 50%;
  }
  .gr--suffix-6\@l {
    margin-right: 50%;
  }
  .gr--prefix-7\@l {
    margin-left: 58.33333%;
  }
  .gr--suffix-7\@l {
    margin-right: 58.33333%;
  }
  .gr--prefix-8\@l {
    margin-left: 66.66667%;
  }
  .gr--suffix-8\@l {
    margin-right: 66.66667%;
  }
  .gr--prefix-9\@l {
    margin-left: 75%;
  }
  .gr--suffix-9\@l {
    margin-right: 75%;
  }
  .gr--prefix-10\@l {
    margin-left: 83.33333%;
  }
  .gr--suffix-10\@l {
    margin-right: 83.33333%;
  }
  .gr--prefix-11\@l {
    margin-left: 91.66667%;
  }
  .gr--suffix-11\@l {
    margin-right: 91.66667%;
  }
  .gr--prefix-12\@l {
    margin-left: 100%;
  }
  .gr--suffix-12\@l {
    margin-right: 100%;
  }
}

.button {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  background: #1681E3;
  color: #ffffff;
}

@media (min-width: 600px) and (max-width: 999px) {
  .button {
    padding: 10px 16px;
    max-width: 320px;
  }
}

@media (min-width: 1000px) {
  .button {
    padding: 22px 16px;
    max-width: 320px;
  }
}

.button--secondary {
  padding: 2px 16px;
  border: 2px solid #1681E3;
  border-radius: 5px;
  background: transparent;
  color: #1681E3;
}

@media (min-width: 1000px) {
  .button--secondary {
    padding: 10px 16px;
  }
}

.button--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.button--icon svg {
  width: 100%;
  height: 100%;
}

@media (min-width: 600px) and (max-width: 999px) {
  .button--icon {
    width: 32px;
    height: 32px;
  }
}

.button--space-top {
  margin-top: 24px;
}

@media (min-width: 1000px) {
  .button--space-top {
    margin-top: 64px;
  }
}

body {
  padding-bottom: 96px;
}

@media (min-width: 600px) {
  body {
    padding-bottom: 160px;
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 32px;
  transform: translate(0, 100%);
  visibility: hidden;
  transition: transform 0.3s, visibility 0.3s;
  z-index: 100;
}

.footer::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 32px;
  background: #f8f8f8;
  border-top: 1px solid #d8d8d8;
}

.footer__action {
  position: relative;
  z-index: 1;
}

.footer--visible {
  transform: none;
  visibility: visible;
}

.image {
  display: block;
}

.image--cover, .image--contain {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.image__img {
  width: 100%;
}

.image--cover .image__img,
.image--contain .image__img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.image--contain .image__img {
  object-fit: contain;
}

.image--position-left-center .image__img {
  object-position: left center;
}

.list-primary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 1000px) {
  .list-primary {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -24px;
  }
}

.list-primary__item {
  padding-top: 20px;
  border-bottom: 2px solid #D50C19;
}

@media (min-width: 1000px) {
  .list-primary__item {
    margin: 0 24px;
    border-bottom-width: 4px;
  }
}

.media {
  display: block;
  position: relative;
  padding-left: 72px;
}

.media__image {
  display: flex;
  justify-content: left;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  width: 72px;
  height: 72px;
  margin-top: -36px;
}

.media--space-bottom {
  margin-bottom: 24px;
}

@media (min-width: 1000px) {
  .media--space-bottom {
    margin-bottom: 64px;
  }
}

.teaser-article {
  display: block;
}

.teaser-article__image {
  display: block;
  overflow: hidden;
  line-height: 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #665a5a;
}

.teaser-article__headline {
  padding-top: 24px;
}

@media (min-width: 1000px) {
  .teaser-article__headline {
    padding-top: 32px;
  }
}

.module-stage {
  padding: 32px 0 0 0;
}

@media (min-width: 0) and (max-width: 599px) {
  .module-stage {
    height: 100vh;
  }
}

@media (min-width: 600px) and (max-width: 999px) {
  .module-stage {
    padding: 40px 0;
  }
}

@media (min-width: 1000px) {
  .module-stage {
    padding-top: 184px;
    padding-bottom: 64px;
  }
}

@media (min-width: 600px) {
  .module-stage__inner {
    position: relative;
  }
}

.module-stage__hero {
  margin: 0 -24px;
}

@media (min-width: 600px) {
  .module-stage__hero {
    margin: 0;
  }
}

@media (min-width: 0) and (max-width: 599px) {
  .module-stage__image {
    position: absolute;
    top: 248px;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: 600px) {
  .module-stage__image {
    position: relative;
    margin-top: -184px;
    height: 504px;
  }
}

@media (min-width: 1000px) {
  .module-stage__image {
    margin-top: -184px;
    padding-bottom: 0;
    height: 100vh;
  }
}

.module-stage__receiver {
  padding: 32px 0;
}

@media (min-width: 600px) and (max-width: 999px) {
  .module-stage__receiver {
    padding-top: 12px;
  }
}

@media (min-width: 1000px) {
  .module-stage__receiver {
    padding-top: 40px;
  }
}

.module-stage__dash-vertical {
  display: block;
  position: absolute;
  top: 216px;
  left: 32px;
  width: 4px;
  height: 208px;
  background: #D50C19;
  z-index: 1;
}

@media (min-width: 600px) {
  .module-stage__dash-vertical {
    display: none;
  }
}

.module-stage__dash-horizontal {
  display: block;
  position: relative;
  width: 600px;
  height: 4px;
  margin-top: 32px;
  background: #D50C19;
  z-index: 1;
}

@media (min-width: 600px) and (max-width: 999px) {
  .module-stage__dash-horizontal {
    width: 280px;
    height: 3px;
    margin-top: 10px;
  }
}

@media (min-width: 0) and (max-width: 599px) {
  .module-stage__dash-horizontal {
    display: none;
  }
}

.module-stage__receiver-logo {
  position: relative;
  height: 40px;
  margin-top: 4px;
  margin-left: -8px;
}

@media (min-width: 600px) and (max-width: 999px) {
  .module-stage__receiver-logo {
    height: 32px;
    margin-top: 8px;
  }
}

@media (min-width: 1000px) {
  .module-stage__receiver-logo {
    height: 80px;
    margin-left: -16px;
  }
}

.module-stage__action-open {
  position: absolute;
  bottom: 32px;
  left: 32px;
  right: 32px;
  z-index: 1;
}

@media (min-width: 600px) and (max-width: 999px) {
  .module-stage__action-open {
    left: 0;
    right: auto;
    width: 152px;
  }
}

@media (min-width: 1000px) {
  .module-stage__action-open {
    left: 0;
    right: auto;
    bottom: 64px;
  }
}

.module-stage__action-message {
  position: absolute;
  bottom: 64px;
  right: 64px;
  z-index: 1;
}

@media (min-width: 0) and (max-width: 999px) {
  .module-stage__action-message {
    display: none;
  }
}

.module-section {
  padding: 24px 0;
}

@media (min-width: 600px) {
  .module-section {
    padding: 64px 0;
  }
}

.module-section__header {
  padding-bottom: 24px;
}

.module-section__teaser {
  padding-bottom: 64px;
}

.module-section__action {
  padding-top: 32px;
}

@media (min-width: 1000px) {
  .module-section__action {
    padding-top: 48px;
  }
}

.module-section__content h3 + p,
.module-section__content p + p {
  padding-top: 24px;
}

@media (min-width: 1000px) {
  .module-section__content h3 + p,
  .module-section__content p + p {
    padding-top: 32px;
  }
}

.module-section__content ul li {
  padding-left: 32px;
}

.module-section__content ul li::before {
  display: inline-block;
  content: "\2B29";
  width: 32px;
  margin-left: -32px;
  color: #D50C19;
}

.module-section__content ul li + li {
  padding-top: 16px;
}

@media (min-width: 1000px) {
  .module-section__content ul li + li {
    padding-top: 24px;
  }
}

.module-section__content + .module-section__articles {
  padding-top: 48px;
}

.module-section__articles {
  margin-top: -24px;
}

.module-section__articles .teaser-article {
  padding-top: 24px;
}
