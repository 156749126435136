
.media {
  display: block;
  position: relative;
  padding-left: 72px;

  &__image {
    display: flex;
    justify-content: left;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 72px;
    height: 72px;
    margin-top: -36px;
  }

  &__headline {
    @extend %t-h3;
  }

  &__subline {
    @extend %t-text;
  }

  &--space-bottom {
    margin-bottom: 24px;

    @include breakpoint(l) {
      margin-bottom: 64px;
    }
  }
}
